<template>
  <div>
    <vue-element-loading :active="loader" spinner="bar-fade-scale" />
    <b-row class="match-height">
      <b-col v-for="x in configList" :key="x.code" md="4" lg="3">
        <b-card :title="x.description">
          <b-card-text>
            <b-form-checkbox v-if="x.code == `IsSystemLive`" v-model="x.value" switch>
              <span>{{ x.value ? "Live" : "Maintenance" }}</span>
            </b-form-checkbox>
            <vue-numeric v-else-if="x.code == `NwSubPrice`" v-model="x.value" class="form-control"></vue-numeric>
            <b-form-input v-else v-model="x.value" trim />
          </b-card-text>
          <template #footer>
            <b-button @click="save(x)" block variant="success"> Save </b-button>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "@api";

export default {
  name: `ConfigurationAdmin`,
  data() {
    return {
      configList: [],
      loader: false,
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async save(rec) {
      this.loader = true;
      try {
        await api.put("config", { code: rec.code, value: rec.value });
        this.showToast(`success`, "Configuration saved successfully");
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loadData();
        this.loader = false;
      }
    },
    async loadData() {
      this.loader = true;
      try {
        this.configList = await api.get(`config`);
        this.configList.forEach(x => (x.value = this.convertValue(x)));
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    convertValue(rec) {
      if (rec.code == `IsSystemLive`) return rec.value == `1`;
      if (rec.code == `NwSubPrice`) return parseFloat(rec.value);
      return rec.value;
    },
  },
};
</script>
